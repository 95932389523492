import { gql } from '@apollo/client';
import { CheckoutPageFragment, CheckoutTotalFragment } from './checkoutPageFragments.gql';
import { OrderConfirmationPageFragment } from '@/components/CheckoutPage/OrderConfirmationPage/api/orderConfirmationPageFragments.gql';

export const CREATE_CART = gql`
    # This mutation will return a masked cart id. If a bearer token is provided for
    # a logged in user it will return the cart id for that user.
    mutation createCart {
        cartId: createEmptyCart
    }
`;

export const PLACE_ORDER = gql`
    mutation placeOrder($cartId: String!, $invoiceRequested: Boolean!) {
        placeOrder(input: { cart_id: $cartId, invoice_requested: $invoiceRequested }) @connection(key: "placeOrder") {
            order {
                order_number
            }
            adyen_payment_status {
                action
                additionalData
                isFinal
                resultCode
            }
        }
    }
`;

export const SET_PAYMENT_METHOD_AND_PLACE_ORDER = gql`
    mutation setPaymentMethodAndPlaceOrder($cartId: String!, $invoiceRequested: Boolean!, $stateData: String!) {
        setPaymentMethodOnCart(
            input: { cart_id: $cartId, payment_method: { code: "adyen_cc", adyen: { state: $stateData } } }
        ) {
            cart {
                selected_payment_method {
                    code
                    title
                }
            }
        }

        placeOrder(input: { cart_id: $cartId, invoice_requested: $invoiceRequested }) {
            order {
                order_number
            }
            adyen_payment_status {
                isFinal
                resultCode
                additionalData
                action
            }
        }
    }
`;

export const SET_ADYEN_HPP_PAYMENT_METHOD_AND_PLACE_ORDER = gql`
    mutation setPaymentMethodAndPlaceOrder(
        $cartId: String!
        $brandCode: String!
        $invoiceRequested: Boolean!
        $stateData: String!
    ) {
        setPaymentMethodOnCart(
            input: {
                cart_id: $cartId
                payment_method: {
                    code: "adyen_hpp"
                    adyen_additional_data_hpp: { brand_code: $brandCode, stateData: $stateData }
                }
            }
        ) {
            cart {
                selected_payment_method {
                    code
                    title
                }
            }
        }

        placeOrder(input: { cart_id: $cartId, invoice_requested: $invoiceRequested }) {
            order {
                order_number
            }
            adyen_payment_status {
                isFinal
                resultCode
                additionalData
                action
            }
        }
    }
`;

// A query to fetch order details _right_ before we submit, so that we can pass
// data to the order confirmation page.
export const GET_ORDER_DETAILS = gql`
    query getOrderDetails($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            ...OrderConfirmationPageFragment
        }
    }
    ${OrderConfirmationPageFragment}
`;

export const GET_CHECKOUT_DETAILS = gql`
    query getCheckoutDetails($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            ...CheckoutPageFragment
        }
    }
    ${CheckoutPageFragment}
`;

export const GET_CHECKOUT_TOTAL = gql`
    query getCheckoutTotal($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            ...CheckoutTotalFragment
        }
    }
    ${CheckoutTotalFragment}
`;

export const GET_CUSTOMER = gql`
    query GetCustomerForCheckout {
        customer {
            id
            default_shipping
            firstname
            email
            is_subscribed
            subscription_status
        }
    }
`;

export const CHECK_IS_EMAIL_AVAILABLE = gql`
    mutation IsEmailAvailable($email: String!) {
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export const SUBSCRIBE_NEWSLETTER = gql`
    mutation subscribeEmailToNewsletter($email: String!, $source: SubscriptionSource) {
        subscribeEmailToNewsletter(email: $email, source: $source) @connection(key: "subscribeEmailToNewsletter") {
            status
        }
    }
`;

export const DEACTIVATE_CART = gql`
    mutation deactivateCart($cartId: String!) {
        deactivateCart(input: { cart_id: $cartId }) {
            result
        }
    }
`;

export default {
    checkIsEmailAvailableMutation: CHECK_IS_EMAIL_AVAILABLE,
    createCartMutation: CREATE_CART,
    deactivateCartMutation: DEACTIVATE_CART,
    getCheckoutDetailsQuery: GET_CHECKOUT_DETAILS,
    getCheckoutTotalQuery: GET_CHECKOUT_TOTAL,
    getCustomerQuery: GET_CUSTOMER,
    getOrderDetailsQuery: GET_ORDER_DETAILS,
    placeOrderMutation: PLACE_ORDER,
    setAdyenHppPaymentMethodAndPlaceOrder: SET_ADYEN_HPP_PAYMENT_METHOD_AND_PLACE_ORDER,
    setPaymentMethodAndPlaceOrder: SET_PAYMENT_METHOD_AND_PLACE_ORDER,
    subscribeNewsletterMutation: SUBSCRIBE_NEWSLETTER,
};
